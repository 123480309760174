import * as React from 'react'
import { CircleOutlineRadioButton } from '@components/form-controls/circle-outline-radio-button'
import clsx from 'clsx'

interface GastroCardBoxProps {
  title: string
  onClick: () => void
  isChecked: boolean
  text: React.ReactNode
  inactiveLabel?: React.ReactNode
}

export const ProductVariantOptionBox = ({
  title,
  onClick,
  isChecked,
  text,
  inactiveLabel,
}: GastroCardBoxProps): React.ReactNode => (
  <div className="position-relative col-lg-4 col-12 px-1">
    <button
      className={clsx('btn-unstyled w-100 h-100', { 'pe-none opacity-25': inactiveLabel })}
      onClick={onClick}
      type="button"
    >
      <div className="rounded border d-flex p-3 bg-lighter-gray h-100 align-items-center">
        <CircleOutlineRadioButton checked={isChecked} />
        <div className="ms-4 text-start">
          <div className="font-lg fw-bold">{title}</div>
          <div className="text-muted font-xs lh-sm">{text}</div>
        </div>
      </div>
    </button>
    {inactiveLabel}
  </div>
)
